<template>
  <div id="applicant" class="rounded-lg shadow-lg p-2">
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center loader"
    >
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <div class="row" v-else>
      <div class="col-md-7"></div>
      <div class="col-md-5 d-flex">
        <router-link
          class="d-block ml-auto"
          v-if="$can('create', 'Job Post')"
          to="/job-post/create"
        >
          <b-button class="btn mt-1 mt-lg-0 add-btn d-block ml-auto">
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add New Job </span>
              <span>
                <img
                  src="@/assets/images/icons/add-icon.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
        </router-link>
        <b-button
          v-if="$can('close job', 'Job Post')"
          id="show-btn"
          @click="$bvModal.show('bv-modal-example')"
          variant="warning"
          class="btn mt-1 mt-lg-0 add-btn d-block ml-auto"
        >
          <div class="d-flex flex-row align-items-center">
            <span class="pr-1"> Close The Job </span>
          </div>
        </b-button>
      </div>
    </div>
    <b-row class="card mx-0 mt-1 py-2">
      <b-col cols="12 p-0">
        <div class="d-flex flex-column flex-md-row justify-content-between">
          <p class="header-text px-1">
            {{ job.title }}
          </p>
        </div>
        <div
          class="d-flex flex-column flex-md-row justify-content-between mx-2"
        >
          <div class="d-felx flex-column align-items-center">
            <div class="d-flex flex-row justify-content-between pb-1">
              <p class="title">Company</p>
              <p class="px-1">:</p>
              <p class="description" v-if="job.user">
                {{ job.user.name }}
              </p>
            </div>
          </div>
          <div class="d-felx flex-column align-items-center">
            <div class="d-flex flex-row justify-content-between pb-1">
              <p class="title">Customer</p>
              <p class="px-1">:</p>
              <p class="description" v-if="job.customer">
                {{ job.customer ? job.customer.name : '' }}
              </p>
            </div>
          </div>
          <div class="d-felx flex-column align-items-center">
            <div class="d-flex flex-row justify-content-between pb-1">
              <p class="title">Working Hours</p>
              <p class="px-1">:</p>
              <p class="description">{{ job.hours }}</p>
            </div>
          </div>
          <!-- <div class="d-felx flex-column align-items-center">
            <div class="d-flex flex-row justify-content-between pb-1">
              <p class="title">Category</p>
              <p class="px-1">:</p>
              <p class="description">{{ job.category ? job.category.name : '' }}</p>
            </div>
          </div> -->
          <div class="d-felx flex-column align-items-center">
            <div class="d-flex flex-row justify-content-between pb-1">
              <p class="title">Job Location</p>
              <p class="px-1">:</p>
              <p class="description">
                {{  job.location ? job.location.name : '' }}
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <job-status
          :applicantCount="applicantCount"
          :progress_checker="progress_checker"
        />
      </b-col>
    </b-row>

    <div
      class="card p-1"
      v-if="
        logedInUser.roles[0].name === 'superadmin' ||
        logedInUser.roles[0].name === 'admin'
      "
    >
      <div class="row">
        <div class="col-12 col-lg-8 d-block">
          <p class="applicant pt-2">Applicants’ List</p>
        </div>

        <div class="col-12 col-lg-4 d-block text-right">
          <b-form-group
            label="Filter By Status"
            label-for="name-input"
            invalid-feedback="Status is required"
            direction="vertical"
          >
            <v-select
              v-if="$can('view applicants', 'Job Post')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="query.status"
              label="name"
              placeholder="Filter by Status"
              :options="applicationStatus"
              @input="getJob"
            />
          </b-form-group>
        </div>
      </div>
      <hr />
      <!-- table -->
      <div
        class="row d-flex align-items-center"
        v-if="$can('update status bulk', 'Job Post')"
      >
        <div class="col-12 col-md-6">
          <b-button size="sm" @click="selectAllRows">Select all</b-button>
          <b-button size="sm" class="mx-1" @click="clearSelected"
            >Clear Selection</b-button
          >
        </div>
        <div class="col-12 col-md-6 d-block">
          <b-form-group
            label="Change Selected Items Status :"
            label-for="name-input"
            invalid-feedback="Company is required"
            direction="vertical"
          >
            <v-select
              v-model="bulkValue"
              placeholder="Change Status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="applicationStatus"
              @input="changeStatusCheckbox(bulkValue)"
            />
          </b-form-group>
        </div>
      </div>
      <b-table
        id="job-table"
        striped
        hover
        :items="applicants"
        :fields="fields"
        :select-mode="selectMode"
        ref="selectableTable"
        selectable
        @row-selected="onRowSelected"
        class="table-card"
      >
        <template
          v-if="$can('update status bulk', 'Job Post')"
          #cell(selected)="{ rowSelected }"
        >
          <div class="selection-column px-1 d-block">
            <div class="d-flex flex-row">
              <template v-if="rowSelected">
                <!-- <span aria-hidden="true">&check;</span> -->
                <img src="@/assets/images/icons/check.png" alt="check" />
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <!-- <span class="unselect shadow-lg" aria-hidden="true"></span> -->
                <img src="@/assets/images/icons/unchek.png" alt="uncheck" />
                <span class="sr-only">Not selected</span>
              </template>
            </div>
          </div>
        </template>
        <!-- <template #cell(label)="row">
          <div class="indicator-column d-block">
            <div class="row">
              <div
                :class="row.item.status ? row.item.status.name : 'N/A'"
                class=""
              ></div>
            </div>
          </div>
        </template> -->
        <template #cell(name)="row">
          <div class="name-column mx-1 d-block">
            <div class="row">
              <!-- v-if="row.item.user && row.item.user.name" -->
              <p class="m-0" v-if="row.item.user && row.item.user.name">
                {{ row.item.user.name }}<br />
              </p>
              <p v-else class="m-0">N/A</p>
            </div>
          </div>
        </template>
        <template #cell(address)="row">
          <div class="name-column mx-1 d-block">
            <div class="row">
              <p class="m-0" v-if="row.item.user && row.item.user.employee">
                {{ row.item.user.employee.address }}
              </p>
              <p v-else class="m-0">N/A</p>
            </div>
          </div>
        </template>
        <template #cell(phone)="row">
          <div class="name-column mx-1 d-block">
            <div class="row">
              <p class="m-0" v-if="row.item.user && row.item.user">
                {{ row.item.phone }}
              </p>
              <p v-else class="m-0">N/A</p>
            </div>
          </div>
        </template>
        <template #cell(email)="row">
          <div class="name-column mx-1 d-block">
            <div class="row">
              <p class="m-0" v-if="row.item.user && row.item.user">
                {{ row.item.user.email }}
              </p>
              <p v-else class="m-0">N/A</p>
            </div>
          </div>
        </template>
        <template #cell(rating)="row">
          <b-input-group
          >
            <b-form-input v-model="row.item.priority"></b-form-input>
            <b-input-group-append>
              <b-button @click.prevent="updatePriority(row.item)" size="sm" text="Button" variant="success"><check-icon size="1x" class="custom-class"></check-icon></b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(cv)="row">
          <div class="mx-2">
            <div class="row">
              <a
                class="m-0"
                v-if="row.item.file"
                :href="$store.state.appConfig.imageBaseUrl + row.item.file"
                target="_blank"
              >
                <img
                  class="mb-1"
                  src="@/assets/images/icons/download.png"
                  width="100"
                  alt=" download"
                />
              </a>
              <span v-else style="width: 100px">No CV</span>
            </div>
          </div>
        </template>
        <template #cell(action)="row">
          <b-form-group class="status-update">
            <v-select
              v-if="$can('update status', 'Job Post')"
              v-model="row.item.status"
              :options="applicationStatus"
              label="name"
              :clearable="false"
              placeholder="Select Status"
              searchable
              @input="changeStatus(row.item)"
            />
          </b-form-group>
        </template>
      </b-table>
      <div>
        <b-pagination
          v-if="total > query.per_page"
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getJob($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>

    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>Are you confirmed to close the job? </template>
      <div class="d-block text-center">
        <h3>
          If you do, you can get this in archived jobs section for further
          future query.
        </h3>
      </div>
      <div class="d-flex justify-content-between text-center">
        <button
          class="mt-3 btn btn-success"
          @click="
            $bvModal.hide('bv-modal-example');
            closeJob();
          "
        >
          Confirm
        </button>
        <button
          class="mt-3 btn btn-danger"
          @click="$bvModal.hide('bv-modal-example')"
        >
          Discard
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store/index";
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
BAvatar,
BBadge,
BButton,
BCard,
BCol,
BContainer,
BDropdown,
BDropdownItem,
BFormCheckbox,
BFormCheckboxGroup,
BFormGroup,
BFormInput,
BFormSelect,
BInputGroup,
BInputGroupAppend,
BModal,
BPagination,
BRow,
BSpinner,
BTable,
} from "bootstrap-vue";
import {
CheckIcon,
} from "vue-feather-icons";
import vSelect from "vue-select";
import jobStatus from "./job-status.vue";
import category from "@/store/category";
export default {
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      job_post_id: "",
      found: "",
      modes: "multi",
      selectMode: "multi",
      selected: [],
      bulkValue: 0,
      status: null,
      changeApplicantStatus: {
        id: null,
        hire: 0,
        // job_post_id: null,
        // users: [],
      },
      changeApplicantStatusCheckBOx: {
        id: null,
        hire: 0,
        job_post_id: null,
        users: [],
      },
      userId: [],
      currentStatus: "",
      applicants: [],
      applicantStatus: [],
      customClass: "rejected",
      conditionalColor: "",
      loading: false,
      job: {},
      originalJob: {},
      companies: [],
      applicantCount: {},
      query: {
        name: "",
        page: 1,
        per_page: 20,
        location: "",
        industry: "",
        status: null,
        id: "",
        reject: null,
      },
      total: 0,
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        { key: "selected", label: "" },
        // "label",
        // { key: "label", label: "" },
        // "selected",
        "name",
        "address",
        "phone",
        "email",
        'rating',
        "Action",
        "cv",
      ],
      // primary_table: [{ key: "show_details", label: "" }],
      // selected: { title: "Square" },
      applicationStatus: [],
      status: 0,
      progress: 0,
      progress_checker: 0,
      // count variables
      apply_count: 0,
      pending_count: 0,
      screening_count: 0,
      interviw_count: 0,
      viva_count: 0,
      rejected_count: 0,
      hired_count: 0,
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BRow,
    BSpinner,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    vSelect,
    jobStatus,
    CheckIcon,
    
  },
  created() {
    const id = this.$route.params.id;
    this.getJob();
  },
  methods: {
    onRowSelected(items, index) {
      this.selected = items;
      this.changeApplicantStatusCheckBOx.users = this.selected.map((x) => x.user_id);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    callCheck(item) {
      this.changeApplicantStatusCheckBOx.users.push(item.user_id);
    },
    changeStatusCheckbox(bulkValue) {
      this.changeApplicantStatusCheckBOx.hire = bulkValue.value;
      this.changeApplicantStatusCheckBOx.job_post_id = this.job_post_id;
      this.$store
        .dispatch("job/changeApplicanStatus", this.changeApplicantStatusCheckBOx)
        .then((res) => {
          this.updateStatus(this.progress_checker);
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Job status updated successfully"
          );
        })
        .catch((err) => {
          this.loading = false;
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Error Updating Customer"
          );
        });
    },
    changeStatus(item) {
      console.log("hello",item)
      this.loading = true;
      this.changeApplicantStatus.hire = item.status.value;
      this.changeApplicantStatus.id = item.id;
      console.log(this.changeApplicantStatus)
      this.$store
        .dispatch("job/changeApplicanStatus", this.changeApplicantStatus)
        .then((res) => {
          this.updateStatus(this.progress_checker);
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Job status updated successfully"
          );
        })
        .catch((err) => {
          this.loading = false;
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Error Updating Customer"
          );
        });
    },
    getJob(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      let tempStatus = this.query.status;
      let tempReject = this.query.reject;
      this.query.id = this.$route.params.id;
      this.query.status = this.query.status?.value;
      this.query.reject = this.query.reject?.value;
      this.loading = true;
      this.$store.dispatch("job/getJob", this.query).then((res) => {
        this.query.total = res.apply.total;
        this.applicationStatus = res.status;
        this.applicants = res.apply.data;
        this.total = res.apply.total;
        this.job_post_id = res.data.id;
        this.applicants.forEach((applicant) => {
          if (applicant.hire == 4) {
            applicant.status = 4;
          }
          applicant.status = this.applicationStatus.find(
            (e) => e.value === applicant.status
          );
          this.conditionalColor = applicant.status.name
            ? applicant.status.name
            : "";
        });
        this.job = res.data;
        this.query.status = tempStatus;
        this.query.reject = tempReject;
        // section for job status
        // this is count section
        // apply count
        this.apply_count = res.data.apply_count;
        // pending_count
        this.pending_count = res.data.pending_count;
        // screening count
        this.screening_count = res.data.screening_count;
        // interview count
        this.interviw_count = res.data.interview_count;
        // viva count
        this.viva_count = res.data.viva_count;
        // rejected count
        this.rejected_count = res.data.rejected_count;

        // hired count
        this.hired_count = res.data.hired_count;
        let applicantCount = {
          apply_count: this.apply_count,
          pending_count: this.pending_count,
          screening_count: this.screening_count,
          interviw_count: this.interviw_count,
          viva_count: this.viva_count,
          rejected_count: this.rejected_count,
          hired_count: this.hired_count,
        };
        this.applicantCount = applicantCount;
        if (this.rejected_count > 0) {
          this.apply_count = this.apply_count - this.rejected_count;
        }
        // if (this.hired_count > 0) {
        //   this.apply_count = this.apply_count - this.hired_count;
        // }
        if (this.pending_count > 0) {
          this.progress_checker = 0;
        }
        if (this.screening_count > 0) {
          this.progress_checker = 2;
        }
        if (this.interviw_count > 0) {
          this.progress_checker = 1;
        }
        if (this.viva_count > 0) {
          this.progress_checker = 3;
        }
        if (this.hired_count > 0) {
          this.progress_checker = 4;
        }
        // old code base commented here for the  future when client will repeant for his dicision

        //       if (
        //   this.pending_count <= this.apply_count &&
        //   this.pending_count !== 0
        // ) {
        //   this.progress_checker = 0;
        // } else if (
        //   this.screening_count <= this.apply_count &&
        //   this.screening_count !== 0
        // ) {
        //   this.progress_checker = 1;
        // } else if (this.interviw_count) {
        //   this.progress_checker = 2;
        // } else if (
        //   this.viva_count <= this.apply_count &&
        //   this.viva_count !== 0
        // ) {
        //   this.progress_checker = 3;
        // } else if (
        //   this.hired_count <= this.apply_count &&
        //   this.hired_count !== 0
        // ) {
        //   this.progress_checker = 4;
        // }

        // old code base commented here for the  future when client will repeant for his dicision
      });
      this.query.status = tempStatus;
      this.query.reject = tempReject;
      this.loading = false;
    },
    FilterJobApplicant() {
      if (this.query.name == "") {
        this.getJob();
      } else {
        this.job.apply = this.job.apply.filter((item) => {
          return (
            item.user.name
              .toLowerCase()
              .includes(this.query.name.toLowerCase()) ||
            item.phone.toLowerCase().includes(this.query.name.toLowerCase()) ||
            item.user.email
              .toLowerCase()
              .includes(this.query.name.toLowerCase())
          );
        });
      }
    },
    updateStatus(item) {
      this.loading = true;
      // this.progress = item;
      this.$store
        .dispatch("job/updateStatus", {
          id: this.$route.params.id,
          progress: this.progress_checker,
        })
        .then((res) => {
          this.getJob();
          // this.updateStatus(this.progress_checker);
          // this.getJob(this.$route.params.id);
          this.loading = false;
        });
    },
    updatePriority(item){
      let data = {
        job_apply_id : item.id,
        priority : item.priority
      }
      this.$store.dispatch("job/updatePriority", data).then((res) => {
        toast(
          "Success",
          "AlertTriangleIcon",
          "success",
          "Priority updated successfully."
        );
        this.getJob();
      }).catch((err) => {
        toast(
          "Error",
          "AlertTriangleIcon",
          "danger",
          err.response?.data?.message || "Error updating employee rating."
        );
      })
    },
    closeJob() {
      this.loading = true;

      this.$store
        .dispatch("job/publishJob", {
          id: this.$route.params.id,
          status: 3,
        })
        .then((res) => {
          this.getJob();
          this.loading = false;
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Job status updated successfully"
          );
          this.$router.push({ name: "job-post" });
        })
        .catch((err) => {
          this.loading = false;
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            err.response?.data?.message || "Error Closing the job"
          );
        });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    
  },
};
</script>

<style scoped>
.vue-select-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.indicator-column {
  padding: 8.5px;
}

.selection-column {
  padding: 10.5px;
}

/* this css for vue select box */
.select-column {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.name-column {
  padding: 6px;
}

.status-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-text {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Blue Soft */

  color: #7190ef;
}

.sub-text {
  /* 14 regular */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text 2 */

  color: #a8adaf;
}

.description {
  /* 14 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.btn-text {
  /* 16 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* navbar css */
.detail-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text Additional */

  color: #79869f;
}

.btm-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;

  /* Text 2 */

  color: #a8adaf;
}

.btn-secondary {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.publish-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
  border-color: white !important;
}

.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

button.btn.preview.rounded-circle.btn-secondary.btn-sm {
  padding: 10px;
}

.rejected {
  background-color: red;
  height: 18px;
  width: 5px;
  margin-right: 2px;
}

.PENDING {
  background-color: black;
  height: 18px;
  width: 5px;
}

.SCREENING {
  background-color: aqua;
  height: 18px;
  width: 5px;
}

.INTERVIEW {
  background-color: yellow !important;
  height: 18px;
  width: 5px;
}

.VIVA {
  background-color: rgb(226, 252, 81) !important;
  height: 18px;
  width: 5px;
}

.HIRED {
  background-color: green !important;
  height: 18px;
  width: 5px;
}

.INTERVIEW {
  background-color: blue;
  height: 18px;
  width: 5px;
}

.REJECTED {
  background: red;
  height: 18px;
  width: 5px;
}

.unselect {
  background-color: rgb(248, 242, 242);
  height: 20px;
  width: 20px;
}

.applicant {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 32px; */
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.btn-margin {
  margin-top: 35px;
}
.status-update {
  min-width: 200px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
